import React from 'react';

import { InfoOutlined } from '@mui/icons-material';
import {
  Button,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses
} from '@mui/material';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.common.black,
    maxWidth: '100%',
    fontSize: theme.typography.pxToRem(12),
    borderColor: theme.palette.primary.main,
    border: '1px solid'
  }
}));

export interface HtmlDbTooltipProps {
  title: string;
  body: string;
}

export function HtmlDbTooltip({ title, body }: HtmlDbTooltipProps) {
  return (
    <HtmlTooltip
      placement="top"
      enterTouchDelay={0}
      title={
        <React.Fragment>
          <Typography
            variant="h5"
            sx={(theme) => ({ color: theme.palette.primary.main })}
          >
            {title}
          </Typography>
          {body}
        </React.Fragment>
      }
    >
      <Button>
        <InfoOutlined />
      </Button>
    </HtmlTooltip>
  );
}
