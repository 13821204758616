import { isDate as isArgDate } from 'date-fns';

export const isString = (arg: any): arg is string => typeof arg === 'string';

export const isNumber = (arg: any): arg is number => typeof arg === 'number';

export const isArrayOfStrings = (arg: any): arg is string[] => {
  if (
    Array.isArray(arg) &&
    arg.every((element) => typeof element === 'string')
  ) {
    return true;
  }

  return false;
};

export const isArrayOfStringsAndNums = (
  arg: any
): arg is (string | number)[] => {
  if (
    Array.isArray(arg) &&
    arg.every(
      (element) => typeof element === 'string' || typeof element === 'number'
    )
  ) {
    return true;
  }

  return false;
};

export const isNull = (arg: any): arg is null => {
  if (arg === null) {
    return true;
  }

  return false;
};

export const isDate = (arg: any): arg is Date => {
  if (isArgDate(arg)) {
    return true;
  }

  return false;
};

export const isObject = (arg: any): arg is object => typeof arg === 'object';

export const isArrayOfObjects = (arg: any): arg is object[] => {
  if (
    Array.isArray(arg) &&
    arg.every((element) => typeof element === 'object')
  ) {
    return true;
  }

  return false;
};
