import React from 'react';

import { SvgIcon } from '@mui/material';

import { ReactComponent as ClockIcon } from './clock.svg';

export function ClockDbIcon() {
  return (
    <SvgIcon>
      <ClockIcon />
    </SvgIcon>
  );
}
