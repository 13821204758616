import axios, { AxiosResponse } from 'axios';

import { EventModel } from '../../../models';

export const getEventsByMonth = async (
  year: string,
  month: string
): Promise<EventModel[]> => {
  const response: AxiosResponse<any, any> = await axios.get(
    (window.configuration?.apiGateway ?? '') +
      '/searchEvents' +
      '/' +
      year +
      '/' +
      month
  );
  return response.data;
};

export const getEventsByDate = async (
  year: string,
  month: string,
  day: string
): Promise<EventModel[]> => {
  const response: AxiosResponse<any, any> = await axios.get(
    (window.configuration?.apiGateway ?? '') +
      '/searchEvents' +
      '/' +
      year +
      '/' +
      month +
      '/' +
      day
  );
  return response.data;
};

export const getEventById = async (eventId: string): Promise<EventModel> => {
  const response: AxiosResponse<any, any> = await axios.get(
    (window.configuration?.apiGateway ?? '') + '/events/' + eventId,
    {}
  );
  return response.data;
};
