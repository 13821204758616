import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Stack, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { EventModel } from '../../../models';
import { AvailabilityIcon } from '../icons';

export interface ListEventItemProps {
  event: EventModel;
}

export function ListEventItem({ event }: ListEventItemProps) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={() => navigate('/event/' + event.id)}>
        <ListItemText
          primary={
            <React.Fragment>
              <Stack sx={{ paddingBottom: '0.2rem' }}>
                <Typography
                  component="span"
                  variant="h5"
                  sx={(theme) => ({
                    display: 'inline',
                    color: theme.palette.primary.main
                  })}
                >
                  {event?.startDate ?? ''}
                </Typography>
              </Stack>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Stack sx={{ paddingBottom: '0.5rem' }}>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="h6"
                >
                  {(i18n?.language === 'en'
                    ? event?.lecture
                      ? event?.eventType?.nameInEnglish +
                        ' - ' +
                        event.lecture.nameInEnglish +
                        (event.language
                          ? ' (' + event.language?.nameInEnglish + ')'
                          : '')
                      : event?.eventType?.nameInEnglish
                    : event?.lecture
                    ? event?.eventType?.nameInGerman +
                      ' - ' +
                      event.lecture.nameInGerman +
                      (event.language
                        ? ' (' + event.language?.nameInGerman + ')'
                        : '')
                    : event?.eventType?.nameInGerman) +
                    ' ' +
                    t('events.from') +
                    ' ' +
                    (event?.startTime ?? '') +
                    ' - ' +
                    (event?.endTime ?? '')}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing={3}
                sx={{ width: '100%' }}
              >
                <AvailabilityIcon
                  color={event.availableSeats > 0 ? 'success' : 'error'}
                  sx={{ fontSize: '0.8rem', width: '5%' }}
                />
                <Typography
                  sx={{ display: 'inline', width: '30%' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {event.availableSeats > 0
                    ? event.availableSeats + ' ' + t('events.availableSeats')
                    : t('events.fullyBooked')}
                </Typography>

                <Button
                  variant="contained"
                  sx={(theme) => ({
                    display: 'inline',
                    backgroundColor: theme.palette.primary.main
                  })}
                >
                  <Typography
                    component="span"
                    variant="body2"
                    fontWeight="bold"
                    sx={(theme) => ({
                      color: theme.palette.info.main
                    })}
                  >
                    {t('events.moreInformation')}
                  </Typography>
                </Button>
              </Stack>
            </React.Fragment>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}
