import React from 'react';

import { SvgIcon } from '@mui/material';

import { ReactComponent as GlobeIcon } from './globe.svg';

export function GlobeDbIcon() {
  return (
    <SvgIcon>
      <GlobeIcon />
    </SvgIcon>
  );
}
