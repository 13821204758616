import React from 'react';

import { SvgIcon } from '@mui/material';

import { ReactComponent as CalendarIcon } from './calendar.svg';

export function CalendarDbIcon() {
  return (
    <SvgIcon>
      <CalendarIcon />
    </SvgIcon>
  );
}
