import React, { useEffect, useRef, useState } from 'react';

import { CircularProgress, Stack } from '@mui/material';

import { EventModel } from '../../../models';
import { getEventsByMonth } from '../../../services/rest';
import { getMonth, getYear, parseDate } from '../../../utils';
import { EventsCalendar } from '../../components';

export function EventsPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [events, setEvents] = useState<EventModel[]>([]);
  const selectedMonthRef = useRef<Date>(new Date());
  selectedMonthRef.current =
    parseDate(window.localStorage.getItem('selectedMonth')) ?? new Date();

  useEffect(() => {
    getEventsByMonth(
      getYear(selectedMonthRef.current),
      getMonth(selectedMonthRef.current)
    )
      .then((response) => {
        setEvents(response ?? []);
      })
      .catch(() => {
        setEvents([]);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Stack spacing={2} sx={{ p: 3, height: '100%' }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <EventsCalendar initialEvents={events} />
      )}
    </Stack>
  );
}
