import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { LanguageModel } from '../../../models';
import { get } from '../client';

export function useLanguages(
  options?: Omit<
    UseQueryOptions<LanguageModel[], Error>,
    'queryKey' | 'queryFn'
  >
) {
  return useQuery(
    ['languages'],
    () => {
      return get<LanguageModel[]>(
        (window.configuration?.apiGateway ?? '') + '/languages'
      );
    },
    options
  );
}
