import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import strings_de from './strings_de.json';
import strings_en from './strings_en.json';

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      en: strings_en,
      de: strings_de
    },
    supportedLngs: ['en', 'de'],
    interpolation: {
      escapeValue: false // react already protects against xss
    },
    detection: {
      caches: []
    }
  });

export default i18n;
