import axios from 'axios';

/**
 * Attempts to perform a REST API request.<br />
 */
async function performRequest<T>(request: () => Promise<T>): Promise<T> {
  try {
    return await request();
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Performs a GET request on the given URL.
 * @return the response (data of type T) that was provided by the server
 */
export async function get<T>(url: string) {
  return (await performRequest(() => axios.get<T>(url, {}))).data;
}

/**
 * Performs a POST request on the given URL.
 * @return the response (data of type T) that was provided by the server
 */
export async function post<T>(url: string, body: any) {
  return (await performRequest(() => axios.post<T>(url, body, {}))).data;
}
