import React from 'react';

import { SvgIcon } from '@mui/material';

import { ReactComponent as PriceIcon } from './price.svg';

export function PriceDbIcon() {
  return (
    <SvgIcon>
      <PriceIcon />
    </SvgIcon>
  );
}
