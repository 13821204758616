import { EventModel, ReservationModel } from '../../models';

export const mapToReservationModel = (
  values: Record<string, any>,
  event: EventModel | undefined
): ReservationModel => {
  return {
    id: 0,
    eventId: event?.id ?? 0,
    firstname: values.firstname,
    lastname: values.lastname,
    email: values.email,
    numberOfGuests: values.numberOfGuests,
    phone: values.phone,
    educationalPurpose: values.educationalPurpose,
    languageId: values.languageId,
    lectureTypeId: values.lectureTypeId,
    personalId: values.personalId
  };
};
