import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { de, enGB } from 'date-fns/locale';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { EventDetails, EventRegister, EventsPage } from './pages';

function App() {
  const { i18n } = useTranslation();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false
      }
    }
  });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={i18n.language === 'en' ? enGB : de}
    >
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<EventsPage />}></Route>
          <Route path="event/:eventId" element={<EventDetails />}></Route>
          <Route
            path="event/:eventId/register"
            element={<EventRegister />}
          ></Route>
        </Routes>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export default App;
