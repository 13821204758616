import React from 'react';

import { SvgIcon } from '@mui/material';

import { ReactComponent as GroupIcon } from './group.svg';

export function GroupDbIcon() {
  return (
    <SvgIcon>
      <GroupIcon />
    </SvgIcon>
  );
}
