import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { List, ListSubheader, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { StaticDatePicker } from '@mui/x-date-pickers';

import { EventModel } from '../../../models';
import { getEventsByDate, getEventsByMonth } from '../../../services/rest';
import {
  getDay,
  getMonth,
  getYear,
  parseDate,
  toIsoDateString
} from '../../../utils';
import { ListEventItem } from './list-event-item';

export interface EventsCalendarProps {
  initialEvents: EventModel[];
}

export function EventsCalendar({ initialEvents }: EventsCalendarProps) {
  const { t } = useTranslation();
  const [events, setEvents] = useState<EventModel[]>(initialEvents);
  const selectedMonthRef = useRef<Date>(new Date());

  selectedMonthRef.current =
    parseDate(window.localStorage.getItem('selectedMonth')) ?? new Date();

  return (
    <Stack
      spacing={4}
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: '100%', height: '100%' }}
    >
      <Box>
        <StaticDatePicker
          sx={(theme) => ({
            height: 334,
            '.MuiPickersCalendarHeader-label': {
              color: theme.palette.primary.main,
              fontWeight: 'bold'
            },
            '.MuiPickersCalendarHeader-switchViewButton': {
              color: theme.palette.primary.main
            },
            '.MuiPickersArrowSwitcher-button:not(.Mui-disabled)': {
              color: theme.palette.primary.main
            },
            '.MuiDayCalendar-header': {
              margin: 0,
              width: '100%'
            },
            '.MuiDateCalendar-root': {
              margin: 0,
              width: { xs: '100%', sm: theme.breakpoints.values.sm }
            },
            '& .MuiPickersDay-today': {
              borderRadius: 0
            },
            '.MuiDayCalendar-weekDayLabel': {
              width: '12%',
              fontSize: '1rem'
            },
            '.MuiPickersDay-dayWithMargin': {
              border: 1,
              borderRadius: 0,
              width: '12%',
              fontSize: '1rem',
              borderColor: theme.palette.primary.main
            },
            '.Mui-disabled.MuiPickersDay-dayWithMargin': {
              border: 0
            },
            bgcolor: theme.palette.background.default,
            border: 0
          })}
          views={['year', 'month', 'day']}
          displayStaticWrapperAs="desktop"
          shouldDisableDate={(day: Date) => {
            return day.getDay() === 0 || day.getDay() === 6;
          }}
          defaultValue={selectedMonthRef.current}
          disablePast
          minDate={new Date()}
          maxDate={
            new Date(new Date().setFullYear(new Date().getFullYear() + 17))
          }
          onMonthChange={(value: Date) => {
            window.localStorage.setItem(
              'selectedMonth',
              toIsoDateString(value)
            );
            getEventsByMonth(getYear(value), getMonth(value))
              .then((response) => {
                setEvents(response ?? []);
              })
              .catch(() => {
                setEvents([]);
              });
          }}
          onChange={(selectedDate) => {
            window.localStorage.setItem(
              'selectedMonth',
              toIsoDateString(selectedDate)
            );
            getEventsByDate(
              getYear(selectedDate),
              getMonth(selectedDate),
              getDay(selectedDate)
            )
              .then((response) => {
                setEvents(response ?? []);
              })
              .catch(() => {
                setEvents([]);
              });
          }}
        />
      </Box>

      <List
        sx={(theme) => ({
          width: '100%',
          maxWidth: { xs: '100%', sm: theme.breakpoints.values.sm, md: 360 },
          bgcolor: theme.palette.background.default,
          overflowY: 'auto'
        })}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={(theme) => ({
              color: theme.palette.primary.main,
              bgcolor: theme.palette.background.default
            })}
          >
            <Typography
              sx={(theme) => ({
                display: 'inline',
                color: theme.palette.primary.main,
                bgcolor: theme.palette.background.default
              })}
              component="span"
              variant="h4"
            >
              {t('events.visits')}
            </Typography>
          </ListSubheader>
        }
      >
        <Divider />
        {events?.map((event) => (
          <ListEventItem key={event.id} event={event} />
        ))}
      </List>
    </Stack>
  );
}
