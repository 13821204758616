import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    background: { default: '#fff', paper: '#f0f0f0' },
    common: { black: '#333' },
    primary: { main: '#009' },
    secondary: { main: '#e6e6e6' },
    error: { main: '#f03333' },
    info: { main: '#d7ebff', dark: '#09f' },
    success: { main: '#6ed700' },
    warning: { main: '#ffe100' }
  },
  shape: {
    borderRadius: 0
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1536
    }
  },
  typography: {
    fontFamily: 'newsGothic_light, Arial, Verdana, Helvetica, sans-serif',
    button: {
      textTransform: 'none'
    },
    h3: {
      color: '#009',
      fontSize: '1.5rem',
      fontWeight: 'bold'
    },
    h4: {
      color: '#009',
      fontSize: '1.2rem',
      fontWeight: 'bold'
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 'bold',
      display: 'flex',
      padding: '6px 0px 5px 0px'
    },
    h6: {
      fontSize: '0.9rem'
    },
    body2: {
      color: '#333',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: 'normal',
      margin: 0
    }
  },
  spacing: (factor: number) => `${0.25 * factor}rem`, // (Bootstrap strategy),
  components: {
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 'bold'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true
      },
      styleOverrides: {
        text: {
          '&:focus': {
            textDecoration: 'underline'
          },
          '&:hover': {
            textDecoration: 'underline'
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background: '#fff'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          background: '#f0f0f0',
          margin: 0,
          paddingLeft: 10
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          background: '#fff'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: '#fff'
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: '#fff'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          cursor: 'pointer'
        }
      }
    }
  }
});

export default theme;
