import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { LectureTypeModel } from '../../../models';
import { get } from '../client';

export function useLectureTypes(
  options?: Omit<
    UseQueryOptions<LectureTypeModel[], Error>,
    'queryKey' | 'queryFn'
  >
) {
  return useQuery(
    ['lectureTypes'],
    () => {
      return get<LectureTypeModel[]>(
        (window.configuration?.apiGateway ?? '') + '/lectureTypes'
      );
    },
    options
  );
}
