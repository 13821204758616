import axios, { AxiosResponse } from 'axios';

import { ReservationModel } from '../../../models';

export const addReservation = async (
  reservation: ReservationModel
): Promise<AxiosResponse<any, any>> => {
  const response: AxiosResponse<any, any> = await axios.post(
    (window.configuration?.apiGateway ?? '') + '/reservations',
    { ...reservation },
    {}
  );
  return response;
};
