import React from 'react';

import { SvgIcon } from '@mui/material';

import { ReactComponent as LocationIcon } from './location.svg';

export function LocationDbIcon() {
  return (
    <SvgIcon>
      <LocationIcon />
    </SvgIcon>
  );
}
