import { Parser } from 'html-to-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Alert,
  Button,
  CircularProgress,
  Stack,
  Typography
} from '@mui/material';
import Box from '@mui/material/Box';

import { EventModel } from '../../../models';
import { getEventById } from '../../../services';
import {
  BackButton,
  CalendarDbIcon,
  ClockDbIcon,
  GlobeDbIcon,
  GroupDbIcon,
  LocationDbIcon,
  PriceDbIcon
} from '../../components';

export function EventDetails() {
  const { t, i18n } = useTranslation();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [event, setEvent] = useState<EventModel>();
  const [error, setError] = useState<any>(undefined);

  useEffect(() => {
    setLoading(true);
    getEventById(eventId ?? '')
      .then((response) => {
        setEvent(response);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [eventId]);

  return (
    <Stack spacing={2} sx={{ p: 3, height: '100%' }}>
      <Stack sx={{ overflow: 'auto' }}>
        <BackButton to="/" />
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography
              variant="h3"
              component="h2"
              data-testid="eventName"
              sx={{ paddingTop: '1.5rem' }}
            >
              {i18n.language === 'en'
                ? event?.lecture
                  ? event?.eventType?.nameInEnglish +
                    ' - ' +
                    event.lecture?.nameInEnglish
                  : event?.eventType?.nameInEnglish
                : event?.lecture
                ? event?.eventType?.nameInGerman +
                  ' - ' +
                  event.lecture?.nameInGerman
                : event?.eventType?.nameInGerman}
            </Typography>
            <Typography
              variant="h5"
              component="h2"
              data-testid="eventTime"
              sx={(theme) => ({
                color: theme.palette.primary.main
              })}
            >
              {t('events.from') +
                ' ' +
                (event?.startTime ?? '') +
                ' - ' +
                (event?.endTime ?? '')}
            </Typography>
            {error && (
              <Alert severity="error" data-testid="errorMessage">
                {error?.response?.data?.message ??
                  t('common.unknownErrorOccurred')}
              </Alert>
            )}
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={5}
              sx={{ paddingTop: '1.5rem', width: '100%' }}
            >
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/images/visitor-center_keyvisual-1280x776.jpg`}
                sx={{ width: { xs: '100%', sm: '40%' }, height: '100%' }}
              />
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.paper,
                  width: { xs: '100%', sm: '60%' },
                  minHeight: { xs: 370, sm: 280 }
                })}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{ p: 3 }}
                >
                  <Typography
                    variant="h6"
                    sx={(theme) => ({
                      color: theme.palette.primary.main,
                      fontWeight: 'bold'
                    })}
                  >
                    {t('eventDetails.importantInformation')}
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                    sx={{ paddingTop: 1 }}
                  >
                    <Typography variant="body2">
                      <CalendarDbIcon />
                    </Typography>
                    <Typography
                      variant="body2"
                      data-testid="eventStartTime"
                      sx={{ paddingTop: '0.15rem' }}
                    >
                      {event?.startDate ?? ''}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                    sx={{ paddingTop: 1 }}
                  >
                    <Typography variant="body2">
                      <ClockDbIcon />
                    </Typography>
                    <Typography
                      variant="body2"
                      data-testid="eventStartEndTime"
                      sx={{ paddingTop: '0.15rem' }}
                    >
                      {t('events.from') +
                        ' ' +
                        (event?.startTime ?? '') +
                        ' ' +
                        t('events.to') +
                        ' ' +
                        (event?.endTime ?? '') +
                        ' ' +
                        t('common.cet')}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                    sx={{ paddingTop: 1 }}
                  >
                    <Typography variant="body2">
                      <LocationDbIcon />
                    </Typography>
                    <Typography
                      variant="body2"
                      data-testid="eventLocation"
                      sx={{ paddingTop: '0.15rem' }}
                    >
                      {(event?.location?.street ?? '') +
                        ' ' +
                        (event?.location?.houseNumber ?? '') +
                        ', ' +
                        (event?.location?.postCode ?? '') +
                        ' ' +
                        (event?.location?.city ?? '')}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                    sx={{ paddingTop: 1 }}
                  >
                    <Typography variant="body2">
                      <PriceDbIcon />
                    </Typography>
                    <Typography
                      variant="body2"
                      data-testid="eventPrice"
                      sx={{ paddingTop: '0.15rem' }}
                    >
                      {(event?.eventType.price ?? 0) > 0
                        ? event?.eventType.price + ' €'
                        : t('eventDetails.free')}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                    sx={{ paddingTop: 1 }}
                  >
                    <Typography variant="body2">
                      <GroupDbIcon />
                    </Typography>
                    <Typography
                      variant="body2"
                      data-testid="eventAvailability"
                      sx={{ paddingTop: '0.15rem' }}
                    >
                      {(event?.availableSeats ?? 0) > 0
                        ? event?.availableSeats +
                          ' ' +
                          t('eventDetails.seatsAvailable')
                        : t('events.fullyBooked')}
                    </Typography>
                  </Stack>
                  {event?.language && (
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={2}
                      sx={{ paddingTop: 1 }}
                    >
                      <Typography variant="body2">
                        <GlobeDbIcon />
                      </Typography>
                      <Typography
                        variant="body2"
                        data-testid="eventLanguage"
                        sx={{ paddingTop: '0.15rem' }}
                      >
                        {i18n.language === 'en'
                          ? event?.language.nameInEnglish
                          : event?.language.nameInGerman}
                      </Typography>
                    </Stack>
                  )}
                  {(event?.availableSeats ?? 0) > 0 && (
                    <Stack
                      direction="row"
                      justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                      alignItems="center"
                      spacing={2}
                      sx={{ padding: 3, width: '100%' }}
                    >
                      <Button
                        variant="contained"
                        data-testid="registerButton"
                        sx={(theme) => ({
                          display: 'inline',
                          width: { xs: '100%', sm: '30%' },
                          backgroundColor: theme.palette.primary.main
                        })}
                        onClick={() => navigate('register')}
                      >
                        <Typography
                          component="span"
                          variant="body2"
                          fontWeight="bold"
                          sx={(theme) => ({
                            color: theme.palette.info.main
                          })}
                        >
                          {t('eventDetails.register') + ' '}
                        </Typography>
                      </Button>
                    </Stack>
                  )}
                </Stack>
              </Box>
            </Stack>

            {event?.lecture && (
              <Stack sx={{ paddingTop: 8 }}>
                <Box data-testid="lectureDescription">
                  {i18n.language === 'en'
                    ? Parser().parse(event.lecture.descriptionInEnglish ?? '')
                    : Parser().parse(event.lecture.descriptionInGerman ?? '')}
                </Box>
              </Stack>
            )}

            <Stack sx={{ paddingTop: 8 }}>
              <Box data-testid="eventDescription">
                {i18n.language === 'en'
                  ? Parser().parse(event?.eventType?.descriptionInEnglish ?? '')
                  : Parser().parse(event?.eventType?.descriptionInGerman ?? '')}
              </Box>
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
}
