import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Link, Stack, Typography } from '@mui/material';

import { BackIcon } from '../icons';

export interface BackButtonProps {
  /** The path to be redirected when clicking the button. */
  to: string;
}

export function BackButton({ to }: BackButtonProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Stack>
      <Link color="inherit" onClick={() => navigate(to)}>
        <Typography
          variant="h6"
          sx={(theme) => ({ color: theme.palette.primary.main })}
        >
          <BackIcon
            sx={{ color: 'primary', fontSize: 'medium', paddingTop: '0.2rem' }}
          />
          {t('common.button.back')}
        </Typography>
      </Link>
    </Stack>
  );
}
