import { format } from 'date-fns';

import { isDate, isNumber } from '../type-guards';

/**
 * Formats the given value as a two-digit number with leading zeroes.
 */
function pad(value: number) {
  return String(value).padStart(2, '0');
}

/**
 * Parses the given input as a UTC date.<br />
 * Returns `undefined` in case the input could not be parsed.
 * <br />
 * Supported formats:
 * <ul>
 *   <li>JavaScript Date</li>
 *   <li>ISO date string - `YYYY-MM-DD`,
 *   `YYYY-MM-DDTHH:mm:ss`, `YYYY-MM-DDTHH:mm:ssZ`,
 *   `YYYY-MM-DDTHH:mm:ss.SSS`, `YYYY-MM-DDTHH:mm:ss.SSSZ`</li>
 *   <li>British date string - `DD/MM/YYYY`, `DD/MM/YYYY HH:mm:ss`, `DD/MM/YYYY HH:mm:ss.SSS`</li>
 *   <li>Milliseconds since Unix Epoch</li>
 * </ul>
 * @see normalizeDate
 */
export function parseDate(
  input: Date | string | number | null | undefined
): Date | undefined {
  if (!input) {
    return undefined;
  }

  if (isDate(input)) {
    return input;
  }

  if (isNumber(input) || input.match(/^\d+$/)) {
    return new Date(+input);
  }

  let result = input.match(
    /^(\d+)-(\d+)-(\d+)(?:T(\d+):(\d+):(\d+)(?:\.(\d{3})\d*)?Z?)?$/
  );
  if (result) {
    return new Date(
      Date.UTC(
        +result[1],
        +result[2] - 1,
        +result[3],
        +(result[4] ?? 0),
        +(result[5] ?? 0),
        +(result[6] ?? 0),
        +(result[7] ?? 0)
      )
    );
  }

  result = input.match(
    /^(\d+)\/(\d+)\/(\d+)(?: (\d+):(\d+):(\d+)(?:\.(\d{3})\d*)?)?$/
  );
  if (result) {
    return new Date(
      Date.UTC(
        +result[3],
        +result[2] - 1,
        +result[1],
        +(result[4] ?? 0),
        +(result[5] ?? 0),
        +(result[6] ?? 0),
        +(result[7] ?? 0)
      )
    );
  }
}

/**
 * Formats the given UTC date as a non-UTC (local) ISO-formatted date string - `YYYY-MM-DD`.<br />
 * Returns an empty string in case no parsable date value was provided.
 * @see parseDate
 */
export const toIsoDateString = (
  date: Date | string | number | null | undefined
) => {
  const parsedDate = parseDate(date);
  if (!parsedDate) return '';
  return (
    parsedDate.getFullYear() +
    '-' +
    pad(parsedDate.getMonth() + 1) +
    '-' +
    pad(parsedDate.getDate())
  );
};

/**
 * Formats the given UTC date as a non-UTC (local) German date string - `DD.MM.YYYY`.<br />
 * Returns an empty string in case no parsable date value was provided.
 * @see parseDate
 */
export const toGermanDateString = (
  date: Date | string | number | null | undefined
) => {
  const parsedDate = parseDate(date);
  if (!parsedDate) return '';
  return (
    pad(parsedDate.getDate()) +
    '.' +
    pad(parsedDate.getMonth() + 1) +
    '.' +
    parsedDate.getFullYear()
  );
};

/**
 * Returns a string time represantion of the given date - `HH:mm`.<br />
 * Returns an empty string in case no parsable date value was provided.
 * @see parseDate
 */
export const getTime = (date: Date | string | number | null | undefined) => {
  const parsedDate = parseDate(date);
  if (!parsedDate) return '';
  return pad(parsedDate.getHours()) + ':' + pad(parsedDate.getMinutes());
};

/**
 * Returns a string year represantion of the given date - `yyyy`.<br />
 * Returns an empty string in case no parsable date value was provided.
 * @see parseDate
 */
export const getYear = (date: Date | string | number | null | undefined) => {
  const parsedDate = parseDate(date);
  if (!parsedDate) return '';
  return pad(parsedDate.getFullYear());
};

/**
 * Returns a string month represantion of the given date - `MM`.<br />
 * Returns an empty string in case no parsable date value was provided.
 * @see parseDate
 */
export const getMonth = (date: Date | string | number | null | undefined) => {
  const parsedDate = parseDate(date);
  if (!parsedDate) return '';
  return pad(parsedDate.getMonth() + 1);
};

/**
 * Returns a string day represantion of the given date - `dd`.<br />
 * Returns an empty string in case no parsable date value was provided.
 * @see parseDate
 */
export const getDay = (date: Date | string | number | null | undefined) => {
  const parsedDate = parseDate(date);
  if (!parsedDate) return '';
  return pad(parsedDate.getDate());
};

/**
 * Returns a string represantion of the given date - `dd. MMM yyyy`. E.g. `28. Juli 2023` <br />
 * Returns an empty string in case no parsable date value was provided.
 * @see parseDate
 */
export const getGermanFullDate = (
  date: Date | string | number | null | undefined
): string => {
  const parsedDate = parseDate(date);
  if (!parsedDate) return '';
  return format(parsedDate, 'dd. MMMM yyyy');
};

/**
 * Formats the given UTC date as a non-UTC (local) German date and time string - `DD.MM.YYYY HH:mm`.<br />
 * Returns an empty string in case no parsable date value was provided.
 * @see parseDate
 */
export const toGermanDateTimeString = (
  date: Date | string | number | null | undefined
) => {
  const parsedDate = parseDate(date);
  if (!parsedDate) return '';
  return (
    pad(parsedDate.getDate()) +
    '.' +
    pad(parsedDate.getMonth() + 1) +
    '.' +
    parsedDate.getFullYear() +
    ' ' +
    pad(parsedDate.getHours()) +
    ':' +
    pad(parsedDate.getMinutes())
  );
};
